.link-with-icon {
  svg {
    margin-left: 0.5rem;
    * {
      fill: $secondary-colour;
      transition: $global-transition;
    }
  }
  &:hover {
    svg {
      * {
        fill: $primary-colour;
      }
    }
  }
}

.button,
button,
input[type='submit'],
input[type='reset'],
input[type='button'],
.com-baforms-wrapper .ba-form-submit-btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  /*background: linear-gradient(
    120deg,
    $quaternary-colour,
    $secondary-colour,
    $tertiary-colour
  );
  background-size: 200% 100%;
  background-position: 100% 0;*/
  background-color: $secondary-colour;
  border: 0;
  color: $white;
  font-size: 0.9rem;
  text-decoration: none;
  line-height: normal;
  font-family: $primary-font;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  letter-spacing: 1px;
  @include transition($global-transition);
  -webkit-appearance: none;

  &:hover {
    color: $white;
    //background-position: 0 0;
    background-color: $secondary-colour-dark;
    border: 0;
  }
  &:active {
    border: 0;
  }
  &:visited {
    color: $white;
  }
}

.button {
  &--full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  &--secondary {
    /*background: linear-gradient(
      120deg,
      $tertiary-colour,
      $quaternary-colour,
      $quaternary-colour-light
    );
    background-size: 200% 100%;
    background-position: 100% 0;*/
    background-color: $tertiary-colour;
    &:hover {
      background-color: $tertiary-colour-dark;
    }
  }
  &--1 {
    background: $primary-colour;
    &:hover {
      background: $primary-colour-dark;
    }
  }
  &--2 {
    background: $secondary-colour;
    &:hover {
      background: $secondary-colour-dark;
    }
  }
  &--3 {
    background: $tertiary-colour;
    &:hover {
      background: $tertiary-colour-dark;
    }
  }
  &--4 {
    background: $quaternary-colour;
    &:hover {
      background: $quaternary-colour-dark;
    }
  }
  &--outline,
  &--outline-2,
  &--outline-3,
  &--outline-4,
  &--outline-white,
  &--outline-black {
    background: transparent;
    color: $primary-colour;
    box-shadow: inset 0 0 0 3px $primary-colour;
    &:hover {
      color: $white;
      background: lighten($primary-colour, 12%);
      box-shadow: inset 0 0 0 3px $primary-colour;
    }
  }
  &--outline-2 {
    box-shadow: inset 0 0 0 3px $secondary-colour;
    &:hover {
      color: $white;
      background: lighten($secondary-colour, 12%);
      box-shadow: inset 0 0 0 3px darken($secondary-colour, 12%);
    }
  }
  &--outline-3 {
    box-shadow: inset 0 0 0 3px $tertiary-colour;
    &:hover {
      color: $white;
      background: lighten($tertiary-colour, 12%);
      box-shadow: inset 0 0 0 3px darken($tertiary-colour, 12%);
    }
  }
  &--outline-4 {
    box-shadow: inset 0 0 0 3px $quaternary-colour;
    &:hover {
      color: $white;
      background: lighten($quaternary-colour, 12%);
      box-shadow: inset 0 0 0 3px darken($quaternary-colour, 12%);
    }
  }
  &--outline-white {
    box-shadow: inset 0 0 0 3px $white;
    color: $white;
    &:hover {
      color: $primary-colour;
      background: $white;
      box-shadow: inset 0 0 0 3px $white;
    }
  }
  &--outline-black {
    box-shadow: inset 0 0 0 3px $black;
    color: $black;
    &:hover {
      color: $white;
      background: $black;
      box-shadow: inset 0 0 0 3px $black;
    }
  }
  &--white,
  &--white-2,
  &--white-3,
  &--white-4 {
    color: $primary-colour;
    background: $white;
    &:visited {
      color: $primary-colour;
    }
    &:hover {
      color: $white;
      background: $primary-colour;
    }
  }
  &--white-2 {
    &:hover {
      background: $secondary-colour;
    }
  }
  &--white-3 {
    &:hover {
      background: $tertiary-colour;
    }
  }
  &--white-4 {
    &:hover {
      background: $quaternary-colour;
    }
  }
  &--black,
  &--black-2,
  &--black-3,
  &--black-4 {
    color: $white;
    background: $black;
    &:hover {
      color: $white;
      background: $dark-grey;
    }
  }
  &--black-2 {
    &:hover {
      background: $secondary-colour;
    }
  }
  &--black-3 {
    &:hover {
      background: $tertiary-colour;
    }
  }
  &--black-4 {
    &:hover {
      background: $quaternary-colour;
    }
  }
  &--arrow {
    svg {
      margin-left: 0.5rem;
    }
  }
  &-wrap {
    &--center {
      text-align: center;
    }
  }
  &--align-right {
    float: right;
  }
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
