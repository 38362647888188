/* Social
================================================== */
.social {
  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    width: auto;
    margin: 0;
    padding: 0;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;

    * {
      fill: $black;
      @include transition($global-transition);
    }
  }

  p {
    display: flex;
    margin: 0;
  }

  .social-fixed & {
    ul {
      display: none;
      position: fixed;
      top: 25%;
      right: 0;
      z-index: 100;
      margin: 0;
      background: $tertiary-colour;

      @include bp(xlarge) {
        display: block;
      }
    }
    h3 {
      display: none;
    }
    li,
    a {
      width: 48px;
      height: 48px;
    }
    li {
      margin: 0;
      padding: 0;
      text-align: center;
    }
    span {
      display: none;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 14px;
      line-height: 100%;
      @include transition($global-transition);
      &:hover {
        background-color: $tertiary-colour-dark;
      }
      * {
        fill: $white;
      }
    }
  }
}

/* Countdown
================================================== */
.event-countdown {
  ul {
    display: flex;
    flex-flow: column;
    align-items: normal;
    justify-content: normal;
    position: relative;
    z-index: 1;
    margin: 1rem auto;
    padding: 0;
    text-align: center;
    font-size: 1.2rem;
    @include bp(smedium) {
      flex-flow: row wrap;
    }
  }
  p {
    display: none;
  }
  li {
    display: inline-flex;
    justify-content: center;
    flex: 1;
    @extend .columns;
    @extend .small-12;
    margin: 0;
    padding: 1rem;
    list-style: none;
    text-align: center;
    color: $primary-colour;
    @include bp(smedium) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include bp(medium) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    div {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }
    span {
      line-height: 100%;
    }
  }
  &__number {
    font-size: 2.6rem;
    font-weight: 600;
    @include bp(xlarge) {
      font-size: 3rem;
    }
  }
  &__text {
    margin-top: 1rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    @include bp(xlarge) {
      font-size: 1.2rem;
    }
  }
}

/* Stats
================================================== */
.stat {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  &__number {
    display: flex;
    color: $secondary-colour;
    font-size: 2.6rem;
    @include bp(xlarge) {
      font-size: 3rem;
    }
  }
  &__text {
    margin-top: 0;
    color: $dark-grey;
    font-size: 1.1rem;
    text-align: center;
    @include bp(xlarge) {
      font-size: 1.2rem;
    }
  }
}
.stats {
  &--circle {
    .stat {
      &__number {
        background: url('#{$image-directory}circle-bg.png') 50% 50% no-repeat;
        background-size: contain;
        margin-bottom: 1rem;
        padding: 40px;
      }
    }
  }
}

/* Map
================================================== */
.map-card {
  max-width: 600px;
  margin: auto;
  @extend .box-shadow-style-1;
  * {
    color: $primary-colour !important;
  }
  h6 {
    display: none;
  }
  p {
    margin: 0;
  }
  .event-location {
    padding: 1.5rem;
    background-color: $white;
    ul {
      padding: 0;
    }
    li {
      display: inline-flex;
      margin: 0;
      padding: 0 0.5rem 0 0;
    }
    &__name {
      width: 100%;
      margin-bottom: 0.5rem !important;
      font-size: 1.2rem;
      strong {
        font-weight: 600;
      }
    }
  }
}

/* Testimonials
================================================== */
.testimonials-slider {
  &__image {
    padding: 1.5rem;
    background-color: $white;
    border-radius: 50%;
    border: 4px solid $tertiary-colour;
  }
  &__quote {
    .n2-ss-item-image-content {
      display: flex;
      position: relative;
      top: -1rem;
      @include bp(large) {
        top: 0;
        left: -4rem;
      }
    }
  }
}

/* Gallery Slider
================================================== */
.showcase-slider {
  .n2-ss-slider-1 {
    @include bp_max(mlarge) {
      min-height: 400px !important;
    }
    @include bp_max(medium) {
      min-height: 360px !important;
    }
  }
  .n2-ss-slide-active {
    @extend .box-shadow-style-2;
  }
  .n2-ss-static-slide {
    z-index: -1 !important;
  }
}

/* Speaker Slider
================================================== */
.speaker-slider {
  &__image {
    overflow: hidden;
    background-color: $white;
    border-radius: 50%;
    border: 4px solid $tertiary-colour;
  }
  &__title {
    margin-bottom: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
  }
  &__details {
    font-size: 0.8rem;
    p {
      margin: 0 0 0.25rem 0;
    }
  }
}

/* Newsletter Form
================================================== */
.newsletter-form {
  .ba-form-field-item,
  .ba-form-column,
  .ba-form-submit-btn {
    margin: 0 !important;
  }
  .ba-form-column-wrapper .ba-form-column {
    align-self: center;
  }
  [class*='span'] {
    width: auto !important;
  }
  input[type='email'],
  .ba-form-submit-btn {
    min-height: 60px;
  }
}

/* Cards
================================================== */
.cards {
  gap: 3rem;
  margin-bottom: 3rem;
  &--narrow {
    max-width: 1024px;
    margin: auto;
  }
  .card {
    position: relative;
    margin-bottom: 3rem;
    padding: 2rem;
    background-color: $secondary-colour;
    * {
      color: $white;
    }
  }
  figure {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 320px !important;
    margin-left: -2rem !important;
    margin-top: -2rem !important;
    overflow: hidden;
    padding-bottom: 1rem;
    width: calc(100% + 4rem);
    img {
      width: 100% !important;
      height: 100% !important;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      -o-object-position: center !important;
      object-position: center !important;
    }
  }
}

/* Box Grid
================================================== */
.boxGrid {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 1em 0;
  &__box {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 1em;
    border: 3px solid $medium-grey;
    margin: 1em 0;
    text-align: center;
    background: $white;
    @include bp(mlarge) {
      width: 30%;
    }
  }
}

/* Arrow
================================================== */
.content__wrap,
.content__wrap .cycle-carousel-wrap {
  width: 100% !important;
}
.content {
  &__wrap {
    &:hover {
      .content-arrows {
        opacity: 1;
      }
    }
  }
  &-arrows {
    display: flex;
    justify-content: center;
    opacity: 0;
  }
  &-arrow {
    display: flex;
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: $secondary-colour;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    &:hover {
      background-color: $secondary-colour-dark;
    }
  }
}
