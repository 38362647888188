h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0.5rem 0 1.5rem;
  font-family: $base-heading-font-family;
  line-height: $base-heading-line-height;
  font-weight: $base-heading-font-weight;
  color: $secondary-colour;
  text-transform: uppercase;
  word-break: break-word;
  span {
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    font-weight: 300;
    font-size: 0.7em;
  }
}
h1,
.h1 {
  font-size: $base-h1-font-size-mobile;
  &--mobile {
    font-size: $base-h1-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h1-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h1-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h1-font-size;
  }
}
h2,
.h2 {
  font-size: $base-h2-font-size-mobile;
  &--mobile {
    font-size: $base-h2-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h2-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h2-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h2-font-size;
  }
}
h3,
.h3 {
  font-size: $base-h3-font-size-mobile;
  &--mobile {
    font-size: $base-h3-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h3-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h3-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h3-font-size;
  }
}
h4,
.h4 {
  font-size: $base-h4-font-size-mobile;
  &--mobile {
    font-size: $base-h4-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h4-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h4-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h4-font-size;
  }
}
h5,
.h5 {
  font-size: $base-h5-font-size-mobile;
  &--mobile {
    font-size: $base-h5-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h5-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h5-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h5-font-size;
  }
}
h6,
.h6 {
  font-size: $base-h6-font-size-mobile;
  &--mobile {
    font-size: $base-h6-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h6-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h6-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h6-font-size;
  }
}

.subheading,
.small-heading {
  margin-bottom: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: $primary-font;
  color: $secondary-colour;
  font-weight: 600;
  &--rotate {
    rotate: 90deg;
  }
}
.small-heading {
  color: $primary-colour;
}
.large-heading {
  font-size: $base-heading-large;
  text-transform: uppercase;
}

.title {
  &--style {
    font-weight: normal;
    &-1 {
      color: $secondary-colour;
    }
  }
}
