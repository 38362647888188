/* Events
================================================== */
.select-event-wrap {
  position: relative;
  z-index: 100;
  display: flex;
  @include bp_max(large) {
    align-items: center;
    position: static;
  }
  .button {
    &--select-event {
      display: none;
      @include bp(xlarge) {
        display: inline-flex;
      }
    }
  }
}
.select-menu-active {
  .social-fixed {
    opacity: 0;
    right: -100px;
  }
}
.event-list__wrap {
  display: none;
  position: absolute;
  top: -0.5rem;
  right: 0;
  z-index: 100;
  padding: 0.5rem;
  background-color: $white;
  @include bp(large) {
    top: 100%;
    right: 0;
    padding: 0;
    background-color: transparent;
    @include box-shadow(none);
  }
  .select-menu-active & {
    display: block;
  }
  .subheading {
    position: absolute;
    margin: 0;
    color: $primary-colour;
    top: 2.2rem;
    left: 1.5rem;
  }
}
.button {
  &--select-event,
  &--select-event-close {
    @include bp_max(medium) {
      padding: 0.75rem 1rem !important;
      font-size: 0.7rem !important;
    }
  }
  &--select-event {
    margin: 0 0 0 auto;
    font-size: 0.8rem;
    color: $white !important;
    @include bp(large) {
      font-size: 0.5rem;
    }
  }
  &--select-event-close {
    margin-top: 1.25rem !important;
    @include bp(large) {
      display: none !important;
    }
    p {
      color: $black;
    }
    span {
      background: transparent;
      &:before,
      &:after {
        top: 0;
        background: $black !important;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
    }
  }
}

.nav {
  &--main {
    .button {
      &--select-event {
        margin-top: 2rem !important;
        font-size: 0.7rem !important;
        @include bp(xlarge) {
          display: none;
        }
      }
    }
  }
}

.event-menu {
  &__links {
    .newslist {
      .latestnews-items {
        flex-direction: column !important;
        width: 100%;
        margin-top: 1rem;
        @include bp(medium) {
          width: 400px;
        }
      }
      .latestnews-item {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding: 0 0 1rem 0 !important;
        transform: translate(0px, 0px) !important;
        opacity: 1 !important;
        visibility: visible !important;
        & > a {
          position: relative;
          overflow: hidden;
          background-color: $black !important;
          border: 0 !important;
        }
      }
      .news {
        position: relative;
        z-index: 20;
      }
      .newsinfo {
        padding: 1.5rem !important;
        background-color: transparent;
        a {
          display: block;
        }
        img {
          width: 100%;
        }
      }
      .newsextra {
        text-align: left;
      }
    }
  }
}
