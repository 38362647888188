/* Banner / Slider
================================================== */
#banner {
  position: relative;
  max-height: 960px;
  overflow: hidden;
  padding: 0;

  .n2-ss-slide-limiter {
    max-width: 100% !important;
  }
  .n2-ss-slider .n2-ss-layer[data-pm='normal'] {
    height: auto !important;
  }
  .n2-ss-section-main-content {
    @include bp_max(medium) {
      justify-content: flex-end !important;
    }
  }
  h1 {
    @include bp_max(mlarge) {
      font-size: 2rem !important;
    }
  }
  .n2-ss-button-container {
    a {
      padding: 0.75rem 1.25rem !important;
      font-weight: 600 !important;
    }
  }
  .slider-button {
    a {
      transition: $global-transition !important;
    }
  }
}
.banner {
  &--main {
    .n2-ss-slide-background-image {
      mix-blend-mode: exclusion;
      opacity: 0.8;
    }
    .n2-ss-slide-background-color {
      z-index: 7 !important;
      mix-blend-mode: color;
      opacity: 0.5;
      background-color: $secondary-colour-light !important;
    }
    .n2-ss-slide-background {
      background-color: $primary-colour-light;
    }
    .n2-ss-button-container {
      a {
        padding: 1rem 1.5rem !important;
        border-radius: 0 !important;
        font-family: $primary-font !important;
        font-weight: 600 !important;
        letter-spacing: 1px !important;
      }
    }
    .button--has-icon-right {
      .n2-ss-button-container {
        a {
          padding-right: 1rem !important;
        }
      }
    }
    .button--has-icon-left {
      .n2-ss-button-container {
        a {
          padding-left: 1rem !important;
        }
      }
    }
  }
  &__overlay,
  &__video-bg {
    height: 100% !important;
  }
  &__overlay {
    mix-blend-mode: color;
  }
  &__video-bg {
    opacity: 0.8;
    .n2_ss_video_player {
      position: relative;
      overflow: hidden;
      height: 100%;
      iframe {
        box-sizing: border-box;
        height: 65vw !important;
        left: 50% !important;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50% !important;
        width: 180vh !important;
      }
    }
  }
  &__text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
    position: absolute;
    z-index: 9;
    top: 20%;
    width: 460px;
    height: 390px;
    border-right: 100px solid transparent;
    border-bottom: 340px solid $primary-colour;

    @include bp_max(large) {
      top: 25%;
      font-size: 0.7em;
    }

    @include bp_max(medium) {
      display: none;
    }

    &__wrap {
      .bannertext {
        height: 100% !important;
        p {
          padding-top: 60px !important;
        }
      }
    }

    p,
    li {
      margin: 0;
      color: $white;
      text-align: left;
      text-transform: uppercase;
      line-height: 0.9;
      font-family: $primary-font;
    }
    p {
      font-size: 3em;
      padding: 80px 20px 20px 20px;
    }
    ul {
      padding-top: 1em;
    }
    li {
      font-size: 1.4em;
      list-style: none;
      padding: 0px 20px;
    }
  }

  &__content {
    margin: 0 2rem 4rem 2rem !important;
    @include bp(mlarge) {
      margin: 0 0 0 5rem !important;
    }
    @include bp_max(xlarge) {
      width: 100% !important;
    }
    .n2-ss-layer-content {
      @include bp_max(medium) {
        padding: 40px !important;
      }
    }
    h1 {
      margin: 0 0 0.5rem 0 !important;
      color: $white !important;
      text-align: left !important;
      text-transform: uppercase !important;
      font-family: $primary-font !important;
      font-size: 2.6rem !important;
      line-height: 1.2 !important;
      padding: 0 !important;
      white-space: normal !important;
    }
    ul,
    ol {
      margin: 0 !important;
    }
    li {
      font-size: 1.4em !important;
      list-style: none !important;
      padding: 0px !important;
    }
    p {
      strong {
        display: block !important;
        margin-top: 1rem !important;
      }
    }
  }
  &__quote {
    * {
      fill: $white !important;
    }
  }
  &__logos {
    .n2-ss-layer-content {
      @include bp_max(medium) {
        padding: 0 !important;
      }
    }
  }
  &-arrow {
    position: absolute;
    width: 2em;
    height: 1em;
    bottom: 3em;
    left: 50%;
    transform: translatex(-50%);
    cursor: pointer;
    z-index: 1;
    @include bp_max(large) {
      display: none;
    }
  }
}
