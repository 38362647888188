.primary-colour {
  color: $primary-colour;
}
.secondary-colour {
  color: $secondary-colour;
}
.tertiary-colour {
  color: $tertiary-colour;
}
.quaternary-colour {
  color: $quaternary-colour;
}
.light-grey-colour {
  color: $light-grey;
}
.medium-grey-colour {
  color: $medium-grey;
}
.dark-grey-colour {
  color: $dark-grey;
}
.black-colour {
  color: $black;
}
.white-colour {
  color: $white;
}
.off-white-colour {
  color: $off-white;
}
.success-colour {
  color: #3adb76;
}
.warning-colour {
  color: #ffae00;
}
.alert-colour {
  color: #cc4b37;
}

.primary-background {
  background-color: $primary-colour;
}
.secondary-background {
  background-color: $secondary-colour;
}
.tertiary-background {
  background-color: $tertiary-colour;
}
.quaternary-background {
  background-color: $quaternary-colour;
}
.light-grey-background {
  background-color: $light-grey;
}
.medium-grey-background {
  background-color: $medium-grey;
}
.dark-grey-background {
  background-color: $dark-grey;
}
.black-background {
  background-color: $black;
}
.white-background {
  background-color: $white;
}
.off-white-background {
  background-color: $off-white;
}
.success-background {
  background-color: #3adb76;
}
.warning-background {
  background-color: #ffae00;
}
.alert-background {
  background-color: #cc4b37;
}

.colour-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 40px;
}

.colour-wrapper {
  margin-right: 30px;
  div {
    width: 75px;
    height: 75px;
    margin-bottom: 0.5rem;
    @extend .box-shadow-style-1;
  }
}

.animation-wrapper {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    text-transform: uppercase;
    color: $white;
    font-size: 1.4rem;
    @extend .box-shadow-style-1;
  }
}
